var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stripe-bank-account" }, [
    _c("h2", [_vm._v("Direct Debit")]),
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "property-item",
        class: { "form-error": _vm.$v.accountName.$error }
      },
      [
        _c(
          "label",
          { staticClass: "field-label", attrs: { for: "account_name" } },
          [_vm._v("Account Name:")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$v.accountName.$model,
              expression: "$v.accountName.$model"
            }
          ],
          staticClass: "text-field w-input",
          attrs: {
            type: "text",
            maxlength: "256",
            placeholder: "Account Name",
            id: "account_name"
          },
          domProps: { value: _vm.$v.accountName.$model },
          on: {
            change: _vm.changeAccountName,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.$v.accountName, "$model", $event.target.value)
            }
          }
        }),
        !_vm.$v.accountName.required
          ? _c("div", { staticClass: "input-error" }, [
              _vm._v("Field is Required")
            ])
          : _vm._e()
      ]
    ),
    _c(
      "label",
      { staticClass: "field-label", attrs: { for: "account_name" } },
      [_vm._v("Account Details:")]
    ),
    _c("div", { ref: "bank-container", attrs: { id: "bank-container" } }),
    _vm.errorMessage
      ? _c("div", { staticClass: "error-messages" }, [
          _vm._v(_vm._s(_vm.errorMessage))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mandate-container" }, [
      _c("div", { staticClass: "align-middle" }, [
        _c("i", { staticClass: "fa fa-info-circle" })
      ]),
      _c("div", { staticClass: "ml-1" }, [
        _vm._v(
          " By providing your bank account details, you agree to this Direct Debit Request and the Direct Debit Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of Protekt Test (the “Merchant”) for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }