<template>
  <div class="setup-subscription">
    <div class="unpaid-popup" v-if="showInvalidMessage">
      <b-alert variant="danger" show dismissible>
        <p>One or more of your devices currently has an expired subscription,
          which has temporarily paused tracking. To resume tracking, please update your subscription details using this
          page.</p>
        If you need any assistance, feel free to email our support team at support@protektgps.com or
        call 1300 95 25 30.</b-alert>
    </div>
    <b-card class="pref-card">
      <loading-box :loading="loading"></loading-box>
      <b-card-body class="flex-nowrap subscribe-content">
        <action-stepper :steps="actionSteps" ref="actionStepper">
          <template v-slot:first>
            <h3>Select a Subscription Tier</h3>
            <div class="form-section">
              Select your desired Service Tier from the list below. Different tiers have different services included.
              Click on a Service Tier to see more information about what it includes.
            </div>
            <div class="form-section selector-container">
              <product-selector @select="selectProduct" :product="product" :products="primaryProducts" vertical
                                :product_types="['primary']"></product-selector>
            </div>
            <p>* Prices are ex GST unless otherwise specified.</p>
          </template>
          <template v-slot:second>
            <div class="flex-column align-content-center justify-content-center align-items-center">
              <h3 class="w-100">Additional Services:</h3>
              <p>Please select any additional services you would like to enable for your account. Prices for each
                additional service are in addition to the service tier you have already selected.
              </p>
              <div class="addon-products" v-if="product && validProductAddons.length">
                <product-selector @select="selectAddons" :products="validProductAddons" select_mode="multi"
                ></product-selector>
              </div>

              <div v-else class="w-100">
                <h4>There are no Service AddOns available for this Service Tier.</h4>
              </div>
              <p class="w-100">* Prices are ex GST</p>
            </div>

          </template>
          <template v-slot:third>
            <SubscriptionSummary v-if="exampleSubscription" :subscription="exampleSubscription"
            ></SubscriptionSummary>
          </template>
          <template v-slot:fourth>
            <h3>Payment Details:</h3>
            <div class="form-section">
              <div class="form-section-card">
                <stripe-card :client-secret="clientSecret" v-show="paymentMethod==='card'" ref="stripeCard" @change="cardEntryChange"></stripe-card>
                <stripe-bank-account :client-secret="clientSecret" v-show="paymentMethod==='bank'" ref="stripeBank"
                                     @change="bankEntryChange" :user="user"></stripe-bank-account>
              </div>
            </div>
          </template>
          <template v-slot:fifth>
            <div class="final-section">
              <h3>Agreement:</h3>
              <div class="agreement-container">
                <stripe-terms-and-conditions :show-d-d="paymentMethod==='bank'"></stripe-terms-and-conditions>
              </div>
              <b-checkbox v-model="agreement">I Understand and Agree to the Above Terms and Service Agreements.</b-checkbox>
            </div>
          </template>
        </action-stepper>
        <div class="flex-row justify-content-around button-bar">
          <button @click="prevAction()" :disabled="disablePrev" class="step-button">
            <i class="fa fa-arrow-left"></i>
            Back
          </button>
          <button @click="nextAction()" :disabled="disableNext" v-if="currentStep !==4" class="step-button">
            Next
            <i class="fa fa-arrow-right"></i>
          </button>
          <button @click="finishAction()" v-if="currentStep === 4" :disabled="!agreement || loading"
                  class="step-button">
            Finish
          </button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import StripeCard from '../components/stripe/StripeCard'
import * as DataProvider from '../components/helpers/DataProvider'
import * as ErrorHelper from '../components/helpers/ErrorHelper'
import * as AlertHelper from '../components/helpers/AlertHelper'
import StripeBankAccount from '../components/stripe/StripeBankAccount'
import ActionStepper from '../components/ActionStepper'
import ProductSelector from '../components/ProductSelector'
import LoadingBox from '@/components/helpers/LoadingBox'
import StripeTermsAndConditions from '@/components/StripeTermsAndConditions'
import ProductAvatar from '@/components/ProductAvatar.vue'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import SubscriptionSummary from '@/components/SubscriptionSummary.vue'

export default {
  name: 'setupSubscription',
  props: {
  },
  components: {
    SubscriptionSummary,
    ProductAvatar,
    StripeTermsAndConditions,
    ProductSelector,
    ActionStepper,
    StripeBankAccount,
    StripeCard,
    LoadingBox
  },
  data: function () {
    return {
      loading: true,
      user: null,
      clientSecret: null,
      setupIntent: null,
      products: [],
      mf: mf,
      product: null,
      productAddons: [],
      currentStep: 0,
      agreement: false,
      paymentOptions: [
        {
          text: 'Credit Card',
          value: 'card'
        },
        {
          text: 'Bank Account (Direct Debit)',
          value: 'bank'
        }
      ],
      paymentMethod: 'card',
      paymentEntryComplete: false,
      paymentConfirmed: false,
      paymentId: null,
      actionSteps: [
        {
          name: 'first',
          title: 'Subscription',
          complete: false,
          active: true
        },
        {
          name: 'second',
          title: 'Extras',
          complete: false,
          active: false
        },
        {
          name: 'third',
          title: 'Review',
          complete: false,
          active: false
        },
        {
          name: 'fourth',
          title: 'Payment',
          complete: false,
          active: false
        },
        {
          name: 'fifth',
          title: 'Confirmation',
          complete: false,
          active: false
        }
      ],
      ChargeTypes: [
        'tracked_assets',
        'untracked_assets',
        'all_assets',
        'users',
        'flat_rate',
      ],
      showChargeTypes: [
        'tracked_assets',
        'flat_rate',
      ],
      unitTypeText: {
        'tracked_assets': 'Tracked Asset',
        'untracked_assets': 'Untracked Asset',
        'all_assets': 'All Asset',
        'users': 'User',
        'flat_rate': 'Flat Rate',
      },
      devices: null,
      showInvalidMessage: false
    }
  },
  async created () {
    // If the user has 'fixable' subscriptions, send them to the account page, as we don't need to create
    // a new subscription for them.
    // if (await this.$auth.hasRecoverableSubscriptions()) {
    //   this.$router.push({ path: '/preferences', query: { tab: 'subscription' } })
    // }

    if (this.$route.query?.reason === 'invalid') {
      this.showInvalidMessage = true
    }

    await this.getMyUser()
    await this.getSetupIntent()
    await this.getProducts()
    this.loading = false
  },
  methods: {
    getMyUser: async function () {
      let res = await DataProvider.getUserProfile()
      if (res.success) {
        this.user = res.data
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    getSetupIntent: async function () {
      let resp = await DataProvider.getSetupIntent()
      console.log(resp)
      if (resp.success) {
        this.setupIntent = resp.data.setup_intent
        this.clientSecret = resp.data.client_secret
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    getProducts: async function () {
      let resp = await DataProvider.getProducts()
      if (resp.success) {
        this.products = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    selectProduct: function (product) {
      console.log(product)
      this.product = product
      this.actionSteps[0].complete = true
    },
    nextAction: function () {
      this.actionSteps[this.currentStep].complete = true
      this.currentStep = this.$refs.actionStepper.nextAction()

    },
    prevAction: function () {
      this.currentStep = this.$refs.actionStepper.prevAction()
    },
    paymentMethodChanged: function (newVal) {
      if (newVal === 'card') {
        this.paymentEntryComplete = this.$refs.stripeCard.isComplete
      } else {
        this.paymentEntryComplete = this.$refs.stripeBank.isComplete
      }
      this.updatePaymentStep(this.paymentEntryComplete)
    },
    cardEntryChange: function (state) {
      if (this.paymentMethod === 'card') {
        this.paymentEntryComplete = state
        this.updatePaymentStep(state)
      }
    },
    bankEntryChange: function (state) {
      if (this.paymentMethod === 'bank') {
        this.paymentEntryComplete = state
        this.updatePaymentStep(state)
      }
    },
    updatePaymentStep: function (state) {
      this.actionSteps[1].complete = state
    },
    // Attempt to complete the subscription setup
    finishAction: async function () {
      this.loading = true
      if (!this.paymentId) {
        console.log('Generating Payment Id...')
        if (this.paymentMethod === 'card') {
          this.paymentId = await this.$refs.stripeCard.createPayment()
        } else {
          this.paymentId = await this.$refs.stripeBank.createPayment()
        }
      }
      console.log('Payment Id: ', this.paymentId)
      if (!this.paymentId) {
        ErrorHelper.displayGeneralErrorToast('Payment Setup Failed. Please check your Payment Information and try again.', 'Payment Setup Failed')
        this.loading = false
        return
      }
      let res = await DataProvider.setPaymentMethod(this.paymentId)
      console.log('Saving Payment Method...', res)
      if (!res.success) {
        ErrorHelper.displayDataErrorToast(res)
        this.loading = false
      }
      console.log('Creating Subscription...')
      let products = [this.product.product_id, ...this.productAddons.map(p => p.product_id)]
      let subResult = await DataProvider.updateSubscription(products, this.paymentId)
      console.log(subResult)
      if (!subResult.success) {
        ErrorHelper.displayDataErrorToast(subResult)
      } else {
        AlertHelper.successToast('Your subscription has been created successfully. Welcome to Protekt!', 'Subscription created!')
        await this.$auth.refreshUserData()

        if (await this.$auth.roleIn(this.$config.roles.fleetscreen)) {
          await this.$router.push({ path: '/' })
        } else {
          await this.$router.push({ path: '/preferences' })
        }
      }
      this.loading = false
    },
    selectAddons(selectedProducts) {
      console.log(selectedProducts)
      this.productAddons = selectedProducts
    }
  },
  computed: {
    primaryProducts () {
      if (this.products) {
        return this.products.filter(p => p.product_type === 'primary' && p.published)
      } else {
        return []
      }
    },
    disableNext: function () {
      console.log('disableNext', this.currentStep)
      if (this.currentStep === 0 && !this.product) {
        return true
      } else if (this.currentStep === 3 && !this.paymentEntryComplete) {
        return true
      } else {
        return false
      }
    },
    disablePrev: function () {
      if (this.currentStep === 0) {
        return true
      } else {
        return false
      }
    },
    paymentText: function () {
      if (this.product) {
        let suffix
        if (this.product.price_interval_count === 1) {
          suffix = ' per ' + this.product.price_interval_unit
        } else {
          suffix = ' every ' + this.product.price_interval_count + ' ' + this.product.price_interval_unit + 's'
        }
        return '$' + this.product.price / 100 + suffix + ' per device.'
      } else {
        return ''
      }
    },
    validProductAddons () {
      console.log('Products: ', this.products)
      if (!this.product || !Object.hasOwn(this.product.data, 'valid_addons')) {
        return []
      } else {
        return this.products.filter(p => p.product_type === 'addon' && this.product.data.valid_addons.includes(p.product_id))
      }
    },
    monthlyChargesByType () {
      if (!this.product) {
        return null
      } else {
        let allProducts = [this.product].concat(this.productAddons)
        let chargeValues = {}
        this.showChargeTypes.forEach((chargeType) => {
          chargeValues[chargeType] = {
            unitType: chargeType,
            unit: this.unitTypeText[chargeType],
            price: allProducts.filter(p => p.billable_unit === chargeType).reduce((total, current) => total + current.price, 0) / 100,
            subitems: allProducts.filter(p => p.billable_unit === chargeType).map((subp) => {
              return {
                name: subp.name,
                price: mf.numberFormat(subp.price / 100, 2)
              }
            })
          }
        })
        return chargeValues
      }
    },
    monthlyChargeList () {
      if (this.monthlyChargesByType) {
        return Object.values(this.monthlyChargesByType)
      } else {
        return []
      }
    },
    billableDeviceCount () {
      if (this.user) {
        console.log('Devices: ', this.user.devices)
        return Object.values(this.user.devices).filter(device => device.billable === true && device.user_id === this.user.user_id).length
      } else {
        return 0
      }
    },
    trackedChargesEstimate() {
      if (this.monthlyChargesByType) {
        return this.monthlyChargesByType['tracked_assets']['price'] * this.billableDeviceCount
      } else {
        return 0
      }
    },
    exampleSubscription() {
      if (this.product && this.productAddons) {
        return {
          products: [this.product].concat(this.productAddons),
          last_volumes: null
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .setup-subscription {
    width: 100vw;
    height: 100vh;
    background: $default-sidebar-background;
    color: $text-color-invert;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .pref-card {
    min-width: 80%;
    padding: 1%;
    min-height: 60vh;
    overflow: auto;
  }

  .card-body {
    justify-content: space-between;
  }

  .form-section {
    margin-bottom: 1em;
    font-size: 1em;
    display: flex;
    justify-content: center;
  }

  .form-section-card {
    width: 75%;
  }

  .final-section {
    margin-bottom: 1em;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    color: $theme-color-primary-3;
  }

  h3 {
    border-bottom: 1px solid $theme-color-primary-6;
    margin-bottom: 1em;
    color: $theme-color-primary-2
  }

  .agreement-container {
    max-height: 30vh;
    overflow: auto;
    padding: 5px 10px;
    margin-bottom: 1em;
    color: $text-color-invert;
  }

  .step-button {
    width: 20vw;
  }

  .total-charges {
    margin-top: 0.5em;
    border-top: 1px solid grey;
    font-weight: 700;
  }

  .charge-item {
    font-weight: 600;
  }

  .sub-item {
    margin-left: 2em;
    font-style: italic;
  }

  .addon-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: 80%;
  }

  .button-bar {
    margin-top: 3em;
  }

  .unpaid-popup {
    width: 80%
  }

  @media screen and (max-width: 600px) {
    .pref-card {
      width: 95%;
    }

    .form-section-card {
      width: 100%;
    }

    .subscribe-content {
      width: 100%;
    }

    .button-bar {
      margin-top: 1em;
    }

    button {
      text-wrap: nowrap;
      font-size: 0.75em;
      padding: 2px 10px;
    }

    .selector-container {
      overflow: auto;
      justify-content: flex-start;
    }

    .addon-products {
      max-width: unset;
    }

    .agreement-container {
      max-height: 60vh;
    }
  }
</style>
