<template>
  <div class="stripe-card">
    <h2>Credit Card</h2>
    <div ref="card-container" id="card-container">
    </div>
    <div class="error-messages" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
// import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as StripeHelper from '../helpers/StripeHelper'

export default {
  name: 'stripe-card',
  props: {
    clientSecret: String
  },
  data: function () {
    return {
      showButtons: true,
      elements: null,
      cardElement: null,
      stripe: null,
      errorMessage: null,
      isComplete: false,
      elementStyle: {
        base: {
          color: '#ffffff',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#ffffff'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a'
          }
        },
        complete: {
          color: '#07d000',
          iconColor: '#07d000',
          ':-webkit-autofill': {
            color: '#07d000'
          }
        }
      }
    }
  },
  created () {
    StripeHelper.injectStripe().then(() => {
      // Note: We're exempting this next line from our linter as it can't see Stripe being dynamically injected
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY)
      if (!this.elements && this.clientSecret) {
        this.createElements()
      }
    })
  },
  methods: {
    createElements: function () {
      const options = {
        style: this.elementStyle,
        disabled: false,
        hideIcon: false,
        iconStyle: 'default' // or "solid"
      }
      this.cardElement = this.stripe.elements().create('card', options)
      this.cardElement.mount('#card-container')
      this.cardElement.on('change', (e) => {
        console.log(e)
        if (e.error) {
          console.log(e.error)
          this.errorMessage = e.error.message
        } else {
          this.errorMessage = null
        }
        this.isComplete = !!e.complete
        this.$emit('change', this.isComplete)
      })
    },
    createPayment: async function () {
      try {
        let res = await this.stripe.confirmCardSetup(
          this.clientSecret,
          { payment_method:
              {
                type: 'card',
                card: this.cardElement
              }
          })
        console.log(res)
        if (res.error) {
          ErrorHelper.displayGeneralErrorToast(res.error.message, 'Stripe Error')
        } else {
          return res.setupIntent.payment_method
        }
      } catch (err) {
        ErrorHelper.displayGeneralErrorToast('There was an error setting up your payment info.', 'Card Setup Error Occurred.')
      }
    }
  },
  watch: {
    clientSecret: async function (newVal) {
      console.log('received secret: ', newVal)
      if (!this.elements && this.stripe) {
        this.createElements()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .stripe-card {
    width: 100%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  #card-container{
    height: 52px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.428571429;
    vertical-align: middle;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #ababab;
    border-radius: 0px;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
  }

  .error-messages {
    font-style: italic;
    color: #ff5858;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 1.5rem;
    }
  }

</style>
