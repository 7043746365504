<template>
<div class="terms-container-stripe">
  <div>
    <TermsAndConditions></TermsAndConditions>
  </div>
  <div v-if="showDD">
    <h3>Direct Debit Request Service Agreement</h3>
    <p>1. By agreeing to the Direct Debit Request you authorise Stripe
    to arrange for funds to be debited from your nominated financial
    institution account (the "nominated account").  Stripe is acting
    as an agent for the Merchant and Stripe does not provide any goods
      or services to you.</p>
    <p>2. Stripe or the Merchant will give you at least 14 days notice
      in writing of any changes to the terms of the drawing arrangements.</p>
    <p>3. Stripe will keep information relating to your nominated account
      confidential in accordance with Stripe’s
      <a href="https://stripe.com/au/privacy">privacy policy</a>, except where
      required for the purposes of conducting direct debits with your
      financial institution. Your personal information will be
      transferred by Stripe to the United States. If you do not want to
      provide your personal information to Stripe in connection with the
      Direct Debit Request, Stripe will not be able to debit your
      nominated account.</p>
    4. Where the due date is not a business day Stripe will draw from
    your nominated financial institution account on the next business
    day.
    <p>5. It is your responsibility to:</p>
      <ol>
    <li>1. Ensure your nominated account can accept direct debits;</li>
    <li>2. Ensure that there are sufficient clear funds available in
      the nominated account to meet each drawing on the due date;</li>
    <li>3. Advise immediately if the nominated account is transferred
      or closed or your account details change;</li>
    <li>4. Arrange a suitable payment method if Stripe or the Merchant
      cancels the drawing arrangements;</li>
    <li>5. Ensure that all authorised signatories nominated on the
      financial institution account to be debited authorise the
      Direct Debit Request.</li>
  </ol>
    <p>6. Subject to the terms and conditions of your nominated financial
      institution account and your agreement with the Merchant, you may
      alter the drawing arrangements. Such advice should be received by
      the Merchant at least 7 business days prior to the drawing date
      for any of the following:
    </p>
    <ol>
      <li> Changing your nominated account number</li>
      <li> Deferring a drawing</li>
      <li> Altering a DDR schedule</li>
      <li> Cancelling the drawings completely</li>
    </ol>
    <p>
      If you require further information, please contact the
      Merchant.Alternatively, you can also contact your financial institution.</p>

    <p>7. If you believe that there has been an error in debiting your
      account, you should notify the Merchant as soon as possible. The
      Merchant will notify you in writing of its determination and the
      amount of any adjustment that will be made to your nominated
      account (if any). Stripe will arrange for your financial
      institution to adjust your nominated account by the applicable
      amount (if any). Alternatively, you can also contact your financial
      institution.</p>
    <p>8. The details of your drawing arrangements are contained in the
      above Direct Debit Request.</p>
    <p>9. Stripe reserves the right to cancel the drawing arrangements if
      three consecutive drawings are dishonoured by your financial
      institution, and for the Merchant to arrange with you an alternative
      payment method. Please refer to the terms and conditions of your
      nominated financial institution account to see whether dishonour
      fees apply. The Merchant may charge additional dishonour fees in
      accordance with your agreement with the Merchant.</p>
  </div>
</div>
</template>

<script>
import TermsAndConditions from '@/components/TermsAndConditions'
export default {
  name: 'terms-and-conditions-stripe',
  components: {
    TermsAndConditions
  },
  props: {
    showDD: Boolean
  }
}
</script>

<style scoped>
.terms-container-stripe {
  text-align: left;
}

.terms-container h3{
  text-align: center;
}
</style>
