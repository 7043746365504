var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "terms-container-stripe" }, [
    _c("div", [_c("TermsAndConditions")], 1),
    _vm.showDD
      ? _c("div", [
          _c("h3", [_vm._v("Direct Debit Request Service Agreement")]),
          _c("p", [
            _vm._v(
              '1. By agreeing to the Direct Debit Request you authorise Stripe to arrange for funds to be debited from your nominated financial institution account (the "nominated account"). Stripe is acting as an agent for the Merchant and Stripe does not provide any goods or services to you.'
            )
          ]),
          _c("p", [
            _vm._v(
              "2. Stripe or the Merchant will give you at least 14 days notice in writing of any changes to the terms of the drawing arrangements."
            )
          ]),
          _vm._m(0),
          _vm._v(
            " 4. Where the due date is not a business day Stripe will draw from your nominated financial institution account on the next business day. "
          ),
          _c("p", [_vm._v("5. It is your responsibility to:")]),
          _vm._m(1),
          _c("p", [
            _vm._v(
              "6. Subject to the terms and conditions of your nominated financial institution account and your agreement with the Merchant, you may alter the drawing arrangements. Such advice should be received by the Merchant at least 7 business days prior to the drawing date for any of the following: "
            )
          ]),
          _vm._m(2),
          _c("p", [
            _vm._v(
              " If you require further information, please contact the Merchant.Alternatively, you can also contact your financial institution."
            )
          ]),
          _c("p", [
            _vm._v(
              "7. If you believe that there has been an error in debiting your account, you should notify the Merchant as soon as possible. The Merchant will notify you in writing of its determination and the amount of any adjustment that will be made to your nominated account (if any). Stripe will arrange for your financial institution to adjust your nominated account by the applicable amount (if any). Alternatively, you can also contact your financial institution."
            )
          ]),
          _c("p", [
            _vm._v(
              "8. The details of your drawing arrangements are contained in the above Direct Debit Request."
            )
          ]),
          _c("p", [
            _vm._v(
              "9. Stripe reserves the right to cancel the drawing arrangements if three consecutive drawings are dishonoured by your financial institution, and for the Merchant to arrange with you an alternative payment method. Please refer to the terms and conditions of your nominated financial institution account to see whether dishonour fees apply. The Merchant may charge additional dishonour fees in accordance with your agreement with the Merchant."
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "3. Stripe will keep information relating to your nominated account confidential in accordance with Stripe’s "
      ),
      _c("a", { attrs: { href: "https://stripe.com/au/privacy" } }, [
        _vm._v("privacy policy")
      ]),
      _vm._v(
        ", except where required for the purposes of conducting direct debits with your financial institution. Your personal information will be transferred by Stripe to the United States. If you do not want to provide your personal information to Stripe in connection with the Direct Debit Request, Stripe will not be able to debit your nominated account."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _vm._v("1. Ensure your nominated account can accept direct debits;")
      ]),
      _c("li", [
        _vm._v(
          "2. Ensure that there are sufficient clear funds available in the nominated account to meet each drawing on the due date;"
        )
      ]),
      _c("li", [
        _vm._v(
          "3. Advise immediately if the nominated account is transferred or closed or your account details change;"
        )
      ]),
      _c("li", [
        _vm._v(
          "4. Arrange a suitable payment method if Stripe or the Merchant cancels the drawing arrangements;"
        )
      ]),
      _c("li", [
        _vm._v(
          "5. Ensure that all authorised signatories nominated on the financial institution account to be debited authorise the Direct Debit Request."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [_vm._v(" Changing your nominated account number")]),
      _c("li", [_vm._v(" Deferring a drawing")]),
      _c("li", [_vm._v(" Altering a DDR schedule")]),
      _c("li", [_vm._v(" Cancelling the drawings completely")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }