
// Inject Stripe library into the current page.
export async function injectStripe () {
  return new Promise(function (resolve, reject) {
    // Check if stripe is already being loaded
    let existingObject = document.getElementById('stripe-library')
    if (existingObject) {
      // Check if Stripe has completed loading
      // eslint-disable-next-line no-undef
      if (typeof Stripe === 'undefined') {
        // Stripe hasn't loaded yet, see if it's already got an onload function, if so, chain onto it.
        if (existingObject.onload) {
          let existingOnLoad = existingObject.onload
          existingObject.onload = (e) => {
            existingOnLoad(e)
            resolve()
          }
        } else {
          existingObject.onload = resolve
        }
      } else {
        resolve()
      }
    } else {
      let object = document.createElement('script')
      let scriptTag = document.getElementsByTagName('script')[0]
      object.id = 'stripe-library'
      object.src = '//js.stripe.com/v3/'
      object.onload = resolve
      object.onerror = reject
      scriptTag.parentNode.insertBefore(object, scriptTag)
    }
  })
}
