<template>
  <div class="stripe-bank-account">
    <h2>Direct Debit</h2>
    <div class="mandate-container">
      <div class="align-middle"><i class="fa fa-info-circle"></i></div>
      <div class="ml-1">
        By providing your bank account details, you agree to this Direct Debit Request and the Direct Debit Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of Protekt Test (the “Merchant”) for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.
      </div>
    </div>
    <div class="property-item" :class="{ 'form-error': $v.accountName.$error }">
      <label for="account_name" class="field-label">Account Name:</label>
      <input type="text" v-model="$v.accountName.$model" class="text-field w-input"
             maxlength="256" placeholder="Account Name" id="account_name" @change="changeAccountName">
      <div class="input-error" v-if="!$v.accountName.required">Field is Required</div>
    </div>
    <label for="account_name" class="field-label">Account Details:</label>
    <div ref="bank-container" id="bank-container">
    </div>
    <div class="error-messages" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
// import * as DataProvider from '../helpers/DataProvider'
// import * as ErrorHelper from '../helpers/ErrorHelper'
import * as StripeHelper from '../helpers/StripeHelper'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'stripe-bank-account',
  props: {
    clientSecret: String,
    user: Object
  },
  data: function () {
    return {
      showButtons: true,
      elements: null,
      cardElement: null,
      stripe: null,
      accountName: '',
      accountEmail: '',
      errorMessage: null,
      isComplete: false,
      elementStyle: {
        base: {
          color: '#ffffff',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#ffffff'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a'
          }
        }
      }
    }
  },
  validations: {
    accountName: {
      required
    }
  },
  created () {
    StripeHelper.injectStripe().then(() => {
      // Note: We're exempting this next line from our linter as it can't see Stripe being dynamically injected
      console.log('Stripe key: ', process.env.VUE_APP_STRIPE_API_KEY)
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY)
      if (!this.elements && this.clientSecret) {
        this.createElements()
      }
    })
    if (this.user) {
      this.accountName = this.user.name
    }
  },
  methods: {
    createElements: function () {
      const options = {
        style: this.elementStyle,
        disabled: false,
        hideIcon: false,
        iconStyle: 'default' // or "solid"
      }
      this.cardElement = this.stripe.elements().create('auBankAccount', options)
      this.cardElement.mount('#bank-container')
      this.cardElement.on('change', (e) => {
        console.log(e)
        if (e.error) {
          console.log(e.error)
          this.errorMessage = e.error.message
        } else {
          this.errorMessage = null
        }
        console.log(this.cardElement)
        this.isComplete = (e.complete && !this.$v.$anyError && this.accountName !== '')
        this.$emit('change', this.isComplete)
      })
    },
    changeAccountName: function (newVal) {
      this.isComplete = !this.$v.$anyError && this.cardElement._complete
      this.$emit('change', this.isComplete)
    },
    createPayment: async function () {
      console.log('email: ', this.accountEmail)
      try {
        let res = await this.stripe.confirmAuBecsDebitSetup(
          this.clientSecret,
          { payment_method:
              {
                type: 'au_becs_debit',
                au_becs_debit: this.cardElement,
                billing_details: {
                  name: this.accountName,
                  email: this.accountEmail
                }
              }
          })
        console.log('Response: ', res)
        if (res.error) {
          ErrorHelper.displayGeneralErrorToast(res.error.message, 'Stripe Error')
        } else {
          return res.setupIntent.payment_method
        }
      } catch (err) {
        console.error(err)
        ErrorHelper.displayGeneralErrorToast('There was an error setting up your payment info.', 'Direct Debit Setup Error Occurred.')
      }
    }
  },
  watch: {
    clientSecret: async function (newVal) {
      console.log('received secret: ', newVal)
      if (!this.elements && this.stripe) {
        this.createElements()
      }
    },
    user: async function (newVal) {
      console.log('Received User: ', newVal)
      if (newVal.name) {
        this.accountName = newVal.name
      }
      if (newVal.email) {
        this.accountEmail = newVal.email
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .stripe-bank-account {
    width: 100%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .field-label {
    color: $text-color-label
  }

  #bank-container{
    height: 52px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.428571429;
    vertical-align: middle;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #ababab;
    border-radius: 0px;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
  }

  .error-messages {
    font-style: italic;
    color: #ff5858;
  }

  .mandate-container {
    display: flex;
    flex-direction: row;
    background: $theme-color-background-1;
    padding: 5px 10px;
    margin-bottom: 1em;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 1.5rem;
    }
  }

</style>
