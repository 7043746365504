var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setup-subscription" },
    [
      _vm.showInvalidMessage
        ? _c(
            "div",
            { staticClass: "unpaid-popup" },
            [
              _c(
                "b-alert",
                { attrs: { variant: "danger", show: "", dismissible: "" } },
                [
                  _c("p", [
                    _vm._v(
                      "One or more of your devices currently has an expired subscription, which has temporarily paused tracking. To resume tracking, please update your subscription details using this page."
                    )
                  ]),
                  _vm._v(
                    " If you need any assistance, feel free to email our support team at support@protektgps.com or call 1300 95 25 30."
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-card",
        { staticClass: "pref-card" },
        [
          _c("loading-box", { attrs: { loading: _vm.loading } }),
          _c(
            "b-card-body",
            { staticClass: "flex-nowrap subscribe-content" },
            [
              _c("action-stepper", {
                ref: "actionStepper",
                attrs: { steps: _vm.actionSteps },
                scopedSlots: _vm._u([
                  {
                    key: "first",
                    fn: function() {
                      return [
                        _c("h3", [_vm._v("Select a Subscription Tier")]),
                        _c("div", { staticClass: "form-section" }, [
                          _vm._v(
                            " Select your desired Service Tier from the list below. Different tiers have different services included. Click on a Service Tier to see more information about what it includes. "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-section selector-container" },
                          [
                            _c("product-selector", {
                              attrs: {
                                product: _vm.product,
                                products: _vm.primaryProducts,
                                vertical: "",
                                product_types: ["primary"]
                              },
                              on: { select: _vm.selectProduct }
                            })
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "* Prices are ex GST unless otherwise specified."
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "second",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex-column align-content-center justify-content-center align-items-center"
                          },
                          [
                            _c("h3", { staticClass: "w-100" }, [
                              _vm._v("Additional Services:")
                            ]),
                            _c("p", [
                              _vm._v(
                                "Please select any additional services you would like to enable for your account. Prices for each additional service are in addition to the service tier you have already selected. "
                              )
                            ]),
                            _vm.product && _vm.validProductAddons.length
                              ? _c(
                                  "div",
                                  { staticClass: "addon-products" },
                                  [
                                    _c("product-selector", {
                                      attrs: {
                                        products: _vm.validProductAddons,
                                        select_mode: "multi"
                                      },
                                      on: { select: _vm.selectAddons }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "w-100" }, [
                                  _c("h4", [
                                    _vm._v(
                                      "There are no Service AddOns available for this Service Tier."
                                    )
                                  ])
                                ]),
                            _c("p", { staticClass: "w-100" }, [
                              _vm._v("* Prices are ex GST")
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "third",
                    fn: function() {
                      return [
                        _vm.exampleSubscription
                          ? _c("SubscriptionSummary", {
                              attrs: { subscription: _vm.exampleSubscription }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "fourth",
                    fn: function() {
                      return [
                        _c("h3", [_vm._v("Payment Details:")]),
                        _c("div", { staticClass: "form-section" }, [
                          _c(
                            "div",
                            { staticClass: "form-section-card" },
                            [
                              _c("stripe-card", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.paymentMethod === "card",
                                    expression: "paymentMethod==='card'"
                                  }
                                ],
                                ref: "stripeCard",
                                attrs: { "client-secret": _vm.clientSecret },
                                on: { change: _vm.cardEntryChange }
                              }),
                              _c("stripe-bank-account", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.paymentMethod === "bank",
                                    expression: "paymentMethod==='bank'"
                                  }
                                ],
                                ref: "stripeBank",
                                attrs: {
                                  "client-secret": _vm.clientSecret,
                                  user: _vm.user
                                },
                                on: { change: _vm.bankEntryChange }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "fifth",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "final-section" },
                          [
                            _c("h3", [_vm._v("Agreement:")]),
                            _c(
                              "div",
                              { staticClass: "agreement-container" },
                              [
                                _c("stripe-terms-and-conditions", {
                                  attrs: {
                                    "show-d-d": _vm.paymentMethod === "bank"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-checkbox",
                              {
                                model: {
                                  value: _vm.agreement,
                                  callback: function($$v) {
                                    _vm.agreement = $$v
                                  },
                                  expression: "agreement"
                                }
                              },
                              [
                                _vm._v(
                                  "I Understand and Agree to the Above Terms and Service Agreements."
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "flex-row justify-content-around button-bar" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "step-button",
                      attrs: { disabled: _vm.disablePrev },
                      on: {
                        click: function($event) {
                          return _vm.prevAction()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v(" Back ")
                    ]
                  ),
                  _vm.currentStep !== 4
                    ? _c(
                        "button",
                        {
                          staticClass: "step-button",
                          attrs: { disabled: _vm.disableNext },
                          on: {
                            click: function($event) {
                              return _vm.nextAction()
                            }
                          }
                        },
                        [
                          _vm._v(" Next "),
                          _c("i", { staticClass: "fa fa-arrow-right" })
                        ]
                      )
                    : _vm._e(),
                  _vm.currentStep === 4
                    ? _c(
                        "button",
                        {
                          staticClass: "step-button",
                          attrs: { disabled: !_vm.agreement || _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.finishAction()
                            }
                          }
                        },
                        [_vm._v(" Finish ")]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }